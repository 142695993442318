export default {
    fromMiliToFormattedTime(miliseconds){
        let _seconds = Math.floor(miliseconds / 1000);
        let _minutes = Math.floor(_seconds / 60);
        let _hours = Math.floor(_minutes / 60);

        _seconds = _seconds % 60;
        _minutes = _minutes % 60;
        
        if(_hours < 10)
            _hours = `0${_hours}`;

        if(_minutes < 10)
            _minutes = `0${_minutes}`;

        if(_seconds < 10)
            _seconds = `0${_seconds}`;

        return `${_hours}:${_minutes}:${_seconds}`;
    }
};