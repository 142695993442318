var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "paginator-container row" }, [
    _c("div", {
      staticClass: "previousArrow circle",
      class: [{ disabled: !_vm.isTherePrevious }],
      on: { click: _vm.previous }
    }),
    _c("div", {
      staticClass: "rightArrow circle",
      class: [{ disabled: !_vm.isThereNext }],
      on: { click: _vm.next }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }